import React, { useState } from "react";

// data
import { socials } from "../navigation";

const Footer = () => {
  const [cookieModal, setCookieModal] = useState(false);

  const showCookiePolicy = () => setCookieModal(!cookieModal);
  return (
    <footer className="">
      <div className="container text-light pt-5">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
            <h4>About Us</h4>
            <div className="footer-content">
              <p>
                <div className="my-3">
                  <h5>noun • ex·tro·py • ˈɛkstrəpi/</h5>
                  <h5>
                    an 'evolving framework of values and standards for
                    continuously improving the human condition'
                  </h5>
                  <p>
                    ---- Max More (2003). Principles of Extropy (Version 3.11)
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6">
            <h4>Contact Us</h4>
            <div className="footer-content">
              <p>
                <small>
                  <u>Address :</u>
                  <address style={{ display: "inline" }}>
                    John Eccles House, Robert Robinson Avenue,Oxford Science
                    Park, Oxford - OX4 4GP, United Kingdom
                  </address>
                </small>
              </p>
              <p>
                <small>
                  <u>Phone :</u> +44 (0) 1865 338228
                </small>
              </p>
              <p>
                <small>
                  <u>Email :</u> info@extropy.io
                </small>
              </p>
              <div className="social-media mt-4">
                {socials.map((social) => (
                  <a
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    <i className={"fab " + social.icon + " mr-4"} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-footer pt-3 pb-3 text-center"
        style={{ backgroundColor: "#001633" }}
      >
        <small>© 2024 Extropy.io</small>
      </div>
    </footer>
  );
};

export default Footer;
