import React from "react";

// components
import Section from "../../components/Section";

const AuditProcess = () => {
  return (
    <Section id="services" color>
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Smart Contracts Audits Process </span>
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
            We offer auditing services for your smart contracts.
          </div>
        </div>
        <div id="accordion">
          <div className="card">
            <div
              className="card-header"
              id="headingOne"
              style={{ backgroundColor: "#002147" }}
            >
              <h5 className="mb-0" style={{ color: "white" }}>
                1. Audit goals
              </h5>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <p>
                  We audit smart contract code according to the following
                  criterias
                </p>
                <ul>
                  <li>
                    <b>Sound Architecture.</b> That is, assessments of the
                    overall architecture and design choices, based on our
                    understanding of your code.
                  </li>
                  <li>
                    <b>Best Practices.</b> We evaluate whether the codebase
                    follows the current established best practices for smart
                    contract development.
                  </li>
                  <li>
                    <b>Code Correctness.</b> The audit includes an evaluation of
                    whether the code does what it is intended to do according to
                    the documentation supplied.
                  </li>
                  <li>
                    <b>Code Quality.</b> We evaluate whether the code has been
                    written in a way that optimises readability and
                    maintainability.
                  </li>
                  <li>
                    <b>Security.</b> We look for any exploitable security
                    vulnerabilities, or other potential threats to the
                    stakeholders of the application.
                  </li>
                  <li>
                    <b>Testing and Testability.</b> We examine both how easily
                    testable the code is, and how thoroughly tested it actually
                    is.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              id="headingTwo"
              style={{ backgroundColor: "#002147" }}
            >
              <h5 className="mb-0" style={{ color: "white" }}>
                2. Security Checklist
              </h5>
            </div>
            <div
              id="collapseTwo"
              className="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div className="card-body">
                <p>
                  Of the above goals, one of the most important will likely be
                  security. We verify the code of your smart contracts against
                  known security vulnerability and to ensure it adheres to these
                  checklist.
                </p>
                <p>
                  The{" "}
                  <a href="https://swcregistry.io/" target="_blank">
                    SWC
                  </a>{" "}
                  (Smart Contract Weakness Classification) registry
                </p>
                <p>
                  A security checklist of known vulnerabilities in smart
                  contracts. The Smart Contract Weakness Classification Registry
                  is an implementation of the weakness classification scheme
                  proposed in{" "}
                  <a
                    href="https://github.com/ethereum/EIPs/issues/1469"
                    target="_blank"
                  >
                    EIP-1470
                  </a>
                  . It is loosely aligned to the terminologies and structure
                  used in the Common Weakness Enumeration (
                  <a href="https://cwe.mitre.org/" target="_blank">
                    CWE
                  </a>
                  ) while overlaying a wide range of weakness variants that are
                  specific to smart contracts.
                </p>
                <p>ERC20-Act</p>
                <p>
                  We also have our own custom ERC20 security checklist, built
                  and based on our previous experience of vulnerabilities found
                  in ERC20 contracts we previously audited.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              id="headingThree"
              style={{ backgroundColor: "#002147" }}
            >
              <h5 className="mb-0" style={{ color: "white" }}>
                3. Correctness
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse show"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div className="card-body">
                Another vital goal of an audit is to determine correctness of
                the code i.e. does it do what it’s intended to do? Clearly this
                has a lot to do with another of our audit goals above: testing
                Indeed, we encourage the inclusion of a test suite as part of
                the application code to be audited! It gives us a good sense of
                (at least) the following:
                <ul>
                  <li>The correctness of the code</li>
                  <li>The testability of the code</li>
                  <li>The developer’s intent</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header"
              id="headingFour"
              style={{ backgroundColor: "#002147" }}
            >
              <h5 className="mb-0" style={{ color: "white" }}>
                4. Tooling
              </h5>
            </div>
            <div
              id="collapseFour"
              className="collapse show"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div className="card-body">
                We use various automation tooling to help us analyse your code
                and find vulnerabilities. These include:
                <ul>
                  <li>Formal verification toolings: Scribble, KLab</li>
                  <li>Static Analysis: Slither, MythX</li>
                  <li>Fuzzer: Foundry, Echidna</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            Preparing for an <span>Audit.</span>
          </h2>
          <div className="section-subtitle m-auto pb-3">
            <p>
              If you decide to audit your contract with Extropy, we recommend
              some steps beforehand to ensure you make the most of our auditing
              service:
            </p>
          </div>
          <div className="container row section-subtitle m-auto">
            <div className="col-4">
              <i
                className="fas fa-laptop-code p-3 text-adjacent-1"
                style={{ fontSize: "3rem", color: "#002147" }}
              ></i>
              <p>Write clean code with a consistent code style</p>
            </div>
            <div className="col-4">
              <i
                className="fas fa-cubes p-3"
                style={{ fontSize: "3rem", color: "#002147" }}
              ></i>
              <p>Use standard libraries where possible</p>
            </div>
            <div className="col-4">
              <i
                className="fas fa-vial p-3"
                style={{ fontSize: "3rem", color: "#002147"  }}
              ></i>
              <p>Include a test suite if present</p>
            </div>
            <div className="col-4">
              <i
                className="fas fa-book p-3 text-adjacent-2"
                style={{ fontSize: "3rem", color: "#002147" }}
              ></i>
              <p>Document the functions of your public API</p>
            </div>
            <div className="col-4">
              <i
                className="fas fa-coins p-3 text-complement"
                style={{ fontSize: "3rem", color: "#002147" }}
              ></i>
              <p>For token issuance document the procedure</p>
            </div>
            <div className="col-4">
              <i
                className="fas fa-users p-3"
                style={{ fontSize: "3rem", color: "#002147" }}
              ></i>
              <p>Include end-user documentation where relevant</p>
            </div>
          </div>
          {/* <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li>Document your protocol and release process</li>
              <li></li>
              <li></li>
            </ul> */}
        </div>
      </div>
    </Section>
  );
};

export default AuditProcess;
