import React from "react";

import ExtropyVideo from "../../assets/video/extropy-video.mp4";

const VideoBackground = () => {
  const styles = {
    video: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      zIndex: -1,
    },
  };

  return (
    <div className="video-container">
      <video style={styles.video} autoPlay loop muted playsInline>
        <source src={ExtropyVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground;
