import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

// assets
import logo from "../../assets/img/extropy_sec_logo.png";

// data
import { menu } from "../navigation";
import AuditRequestButton from "../../components/RequestAuditButton";

const Nav = () => {
  const [navClass, setNavClass] = useState("");
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 200) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className="container">
        <a className="navbar-brand" href="!#">
          <NavHashLink to="/">
            <img src={logo} alt="Extropy.io logo" style={{ width: 300 }} />
          </NavHashLink>
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return "open";
            return "";
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return "show";
            return "";
          })()}`}
        >
          <ul className="navbar-nav ml-auto">
            {menu.map((item) => {
              if (item.type === "page") {
                return (
                  <li className="nav-item nav-link" onClick={toggleNav}>
                    <RouterLink to={item.path}>{item.title}</RouterLink>
                  </li>
                );
              } else {
                return (
                  <li className="nav-item nav-link" onClick={toggleNav}>
                    <NavHashLink
                      to={"/#" + item.target}
                      smooth={true}
                      activeClassName="active"
                    >
                      <span>{item.title}</span>
                    </NavHashLink>
                  </li>
                );
              }
            })}
            <li
              className="nav-item"
              style={{
                marginLeft: window.innerWidth > 768 ? "20px" : "0",
                marginTop: "4px",
                display: "flex",
                justifyContent:
                  window.innerWidth > 768 ? "flex-start" : "center",
                width: "100%",
              }}
            >
              <div onClick={toggleNav}>
                <AuditRequestButton />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
