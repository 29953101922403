import React from "react";

import BlockchainProtocolLogos from "./ChainLogos";
import Section from "../../components/Section";
import AuditRequestButton from "../../components/RequestAuditButton";
import GridBackground from "./GridBackground";

// [TODO]: scale down first section when mobile;
// send mina logo to Erick;

const Home = () => {
  return (
    <Section id="home" color>
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <div className="mr-auto ml-auto text-left hero-text">
            Empowering the decentralized ecosystem with top-tier smart contract{" "}
            <span style={{ color: "rgb(237, 37, 78)" }}>protection</span>,
            innovative development resources, and expert-led blockchain{" "}
            <span style={{ color: "rgb(237, 37, 78)" }}>education</span>.
            <br />
            <br />
            <span className="hero-subtext">
              Contact our team of experts to get a quote on your next audit,
              available on:
            </span>
          </div>
        </div>
        <BlockchainProtocolLogos />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <AuditRequestButton />
        </div>
      </div>
    </Section>
  );
};

export default Home;
