import React, { useState } from "react";
import Section from "../../components/Section";

const SecurityAuditForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telegram: "",
    codebaseLink: "",
    additionalInfo: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");

    try {
      const response = await fetch(
        "https://bjuzidtstq356xmaubucfiayy40rzmsg.lambda-url.eu-west-2.on.aws/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setSubmitMessage("Your request has been submitted successfully!");
        setFormData({
          name: "",
          email: "",
          telegram: "",
          codebaseLink: "",
          additionalInfo: "",
        });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const styles = {
    container: {
      color: "#6f6f6f",
      padding: "32px",
      textAlign: "center",
    },
    form: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "24px",
      maxWidth: "800px",
      margin: "0 auto",
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      marginBottom: "8px",
      textAlign: "left",
    },
    input: {
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #6f6f6f",
      borderRadius: "5px",
      color: "#6f6f6f",
      padding: "8px",
      outline: "none",
    },
    textarea: {
      width: "100%",
      backgroundColor: "white",
      border: "1px solid #6f6f6f",
      borderRadius: "5px",
      color: "#6f6f6f",
      padding: "8px",
      outline: "none",
      resize: "vertical",
    },
    button: {
      backgroundColor: "#002147",
      color: "white",
      fontWeight: "bold",
      padding: "8px 16px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "0 auto",
    },
  };

  return (
    <Section id="auditForm">
      <div style={styles.container} className="section-header">
        <h2 className="section-title">
          <span>Start your security audit, get a quote!</span>
        </h2>

        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="name" style={styles.label}>
              Name *
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              style={styles.input}
              placeholder="John Smith"
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>
              Email *
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={styles.input}
              placeholder="example@gmail.com"
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="telegram" style={styles.label}>
              Telegram
            </label>
            <input
              type="text"
              id="telegram"
              name="telegram"
              value={formData.telegram}
              onChange={handleChange}
              style={styles.input}
              placeholder="@username"
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="codebaseLink" style={styles.label}>
              Codebase link
            </label>
            <input
              type="text"
              id="codebaseLink"
              name="codebaseLink"
              value={formData.codebaseLink}
              onChange={handleChange}
              style={styles.input}
              placeholder="#"
            />
          </div>

          <div style={{ ...styles.formGroup, gridColumn: "1 / -1" }}>
            <label htmlFor="additionalInfo" style={styles.label}>
              Additional information
            </label>
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              style={styles.textarea}
              rows="3"
              placeholder="Tell us your deadline and scope. The more details we have the more accurate the quote will be!"
            ></textarea>
          </div>

          <div style={{ gridColumn: "1 / -1", textAlign: "center" }}>
            <button type="submit" style={styles.button} disabled={isSubmitting}>
              {isSubmitting ? "SUBMITTING..." : "GET A QUOTE"}
            </button>
          </div>

          {submitMessage && (
            <div
              style={{
                gridColumn: "1 / -1",
                textAlign: "center",
                marginTop: "20px",
                color: submitMessage.includes("error") ? "red" : "green",
              }}
            >
              {submitMessage}
            </div>
          )}
        </form>
      </div>
    </Section>
  );
};

export default SecurityAuditForm;
