import React, { useEffect, useRef, useState } from "react";
import eth from "../../assets/img/chainLogos/eth.png";
import base from "../../assets/img/chainLogos/base.png";
import mina from "../../assets/img/chainLogos/mina.png";
import starknet from "../../assets/img/chainLogos/starknet.png";
import binance from "../../assets/img/chainLogos/binance.png";

const BlockchainProtocolLogos = () => {
  const logoRefs = useRef([]);

  const logos = [
    { src: eth, alt: "Ethereum" },
    { src: base, alt: "Base" },
    { src: mina, alt: "Mina" },
    { src: starknet, alt: "Starknet" },
    { src: binance, alt: "Binance" },
  ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {logos.map((logo, index) => (
        <img
          className="chain-logo"
          key={index}
          ref={(el) => (logoRefs.current[index] = el)}
          src={logo.src}
          alt={logo.alt}
        />
      ))}
    </div>
  );
};

export default BlockchainProtocolLogos;
