import React from "react";

import Section from "../../components/Section";
import Testimonial from "./Testimonial";
import PwnLogo from "../../assets/img/clients/pwn.svg";
import FloatLogo from "../../assets/img/clients/floatProtocol.svg";

const Testimonials = () => {
  return (
    <Section id="testimonials">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 text-center">
          <h2 className="section-title">
            <span>Our </span>Testimonials
          </h2>
          <p className="section-subtitle mr-auto ml-auto">
            What our clients say about us!
          </p>
        </div>
      </div>
      <div className="section-content">
        <div className="row text-center pb-5">
          <div className="col-md-3 col-lg-3 mr-auto ml-auto pt-2">
            <Testimonial author={"Josef J, PWN founder"} companyLogo={PwnLogo}>
              We are very grateful to Extropy for their work. They have been our
              go to auditors ever since we started building PWN! You are the
              heroes!
            </Testimonial>
          </div>
          <div className="col-md-3 col-lg-3 mr-auto ml-auto pt-2">
            <Testimonial author={""} companyLogo={FloatLogo}>
              <p style={{ fontSize: "14px" }}>
                We wanted to go with an auditor we trusted immensely, someone
                who has worked with early-stage teams before, someone who we
                knew would go above and beyond...Laurence and Extropy fit the
                bill perfectly
              </p>
              Here's the full article:
              <a
                href="https://medium.com/@extropy-io/positive-feedback-from-float-protocol-28c0d123f8c5"
                target="_blank"
              >
                here
              </a>
            </Testimonial>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Testimonials;
