import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  CardBody,
  CardGroup,
  CardTitle,
  CardText,
  Col,
  FormGroup,
  Row,
  Input,
  Label,
} from "reactstrap";
import etherscanLogo from "../../assets/img/etherscan-logo.png";
import AdvantageFinal from "../../assets/pdf/AdvantageFinal.pdf";
import AnimalConcerts from "../../assets/pdf/AnimalConcertsFinalReport.pdf";
import Float from "../../assets/pdf/FloatProtocolReport.pdf";
import Gemini from "../../assets/pdf/GeminiFinal.pdf";
import Helix from "../../assets/pdf/Helix.pdf";
import PWNAudit from "../../assets/pdf/PWNAudit.pdf";
import PWNAudit2 from "../../assets/pdf/PWNAuditMay2024.pdf";
import PWNProject from "../../assets/pdf/ReviewReport.pdf";
import Smuggle from "../../assets/pdf/SmuggleFinal2.pdf";
import Almanac from "../../assets/pdf/AlmanacNFT.pdf";
import Pwn6 from "../../assets/pdf/PWN6.pdf";
import Social from "../../assets/pdf/Social.pdf";
import Comoco from "../../assets/pdf/Comoco.pdf";
import Whirl from "../../assets/pdf/Whirl.pdf";
import ZkNoid from "../../assets/pdf/zkNoidAuditAug2024Final.pdf";
import PWNAdapters from "../../assets/pdf/PWN_Adapters_Oct2024Final.pdf";

import defiAuditsSummary from "./defiAuditsSummary";

const Audits = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("All");

  const audits = [
    { name: "PWN Adapters Audit", file: PWNAdapters, language: "evm" },
    { name: "ZkNoid Audit", file: ZkNoid, language: "mina" },
    { name: "PWN second Audit", file: PWNAudit2, language: "evm" },
    { name: "Advantage Final Audit", file: AdvantageFinal, language: "evm" },
    { name: "Animal Concerts Audit", file: AnimalConcerts, language: "evm" },
    { name: "Float protocolAudit", file: Float, language: "evm" },
    { name: "Gemini Audit", file: Gemini, language: "evm" },
    { name: "Helix Audit", file: Helix, language: "evm" },
    { name: "PWN Audit", file: PWNAudit, language: "evm" },
    { name: "PWN Project Audit", file: PWNProject, language: "evm" },
    { name: "The Smuggleverse Audit", file: Smuggle, language: "evm" },
    { name: "PWN Multitoken Audit", file: Pwn6, language: "evm" },
    { name: "Comoco Audit", file: Comoco, language: "cairo" },
    { name: "Starknet Social", file: Social, language: "cairo" },
    { name: "Almanac NFT Audit", file: Almanac, language: "cairo" },
    { name: "Whirl Finance Audit", file: Whirl, language: "evm" },
  ];

  const filteredAudits =
    selectedLanguage === "All"
      ? audits
      : audits.filter(
          (audit) =>
            audit.language.toLowerCase() === selectedLanguage.toLowerCase()
        );

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  return (
    <div style={{ padding: "10% 5% 2.5% 5%" }}>
      <h1>Public Audits</h1>
      <Row className="mb-4">
        <Col>
          <p>Some of the recent audits that we've been working on</p>
        </Col>
        <Col xs="auto">
          <FormGroup style={{ width: "200px" }}>
            <Label>
              Filter by language:
              <Input
                type="select"
                bsSize="sm"
                name="language"
                id="languageSelect"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <option value="All">All Languages</option>
                <option value="EVM">EVM</option>
                <option value="Cairo">Cairo</option>
                <option value="Mina">Mina</option>
              </Input>
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <CardGroup>
        {filteredAudits.map((file, index) => (
          <Col sm="4" key={index}>
            <Card>
              <CardBody>
                <CardTitle tag="h5">{file.name}</CardTitle>
                <CardText>
                  Click below to see pdf version of this audit
                </CardText>
                <a href={file.file} rel="noopener noreferrer" target="_blank">
                  <Button
                    style={{ backgroundColor: "#002147", color: "white" }}
                  >
                    {file.name}
                  </Button>
                </a>
              </CardBody>
            </Card>
          </Col>
        ))}
      </CardGroup>
    </div>
  );
};

const DefiAudits = () => {
  const getIconColour = (faIcon) => {
    if (faIcon == "fa-check-circle") return "green";
    if (faIcon == "fa-question-circle") return "orange";
    if (faIcon == "fa-times-circle") return "red";
  };

  return (
    <div style={{ padding: "2.5% 5%" }}>
      <h1>Defi Landscape - Audits Summary</h1>
      <p>
        In the interests of making the Defi space more transparent for
        investors, we've put together a list of audit reports from different
        Defi firms.{" "}
      </p>
      <p>
        We've also included a list of the main points from each report, or any
        impartial comments regarding firms who may have not had an audit report.{" "}
      </p>
      <p>
        For security researchers and smart contract auditors, you will also find
        in this table a{" "}
        <strong>link to the audited contracts on Etherscan</strong> to further
        analysis of the smart contracts and Solidity code.
      </p>
      <p>
        <div class="alert alert-primary" role="alert">
          <h5>Disclaimer:</h5>
          <p>
            We strongly recommend that investors read through the reports and
            research each firm independently. Our main points are quotes from
            each report. A new audit report may have been carried out since we
            last updated the table, therefore the points may not reflect the
            present state of security.
          </p>
        </div>
      </p>
      <table class="table table-bordered">
        <tr style={{ color: "#fcfcfc", backgroundColor: "#333985" }}>
          <th class="text-center">
            <h5>Defi Firm</h5>
          </th>
          <th class="text-center">
            <h5>Audit Report</h5>
          </th>
          <th>
            <h5>Main points from report / comments</h5>
          </th>
          <th class="text-center">
            <h5>View report(s)</h5>
          </th>
          <th class="text-center">
            <h5>Smart Contract(s)</h5>
          </th>
        </tr>
        {defiAuditsSummary.map((report) => {
          return (
            <tr>
              <td class="text-center">
                <b>{report.defi_project_name}</b>
              </td>
              <td class="text-center" style={{ fontSize: "1.5rem" }}>
                <i
                  class={"fas " + report.icon}
                  style={{ color: getIconColour(report.icon) }}
                ></i>
              </td>
              <td>
                <details>
                  <summary>Click to expand</summary>
                  <u>auditor:</u>&nbsp;{report.auditor}
                  <p>{report.summary}</p>
                </details>
              </td>
              <td class="text-center">
                <a href={report.report_link} target="_blank">
                  <i
                    width={50}
                    class="mx-2 fas fa-file-pdf"
                    style={{ color: "#AA3939", fontSize: "1.5rem" }}
                  ></i>
                  click to audit report in pdf
                </a>
              </td>
              <td class="text-center">
                <a href={report.smart_contract_link} target="_blank">
                  <img class="mx-2" src={etherscanLogo} width={30} /> view on
                  Etherscan
                </a>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const Main = () => {
  return (
    <>
      <Audits />
      <DefiAudits />
    </>
  );
};

export default Main;
