import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const buttonStyle = {
  // backgroundColor: "#4d539a",
  backgroundColor: "#002147",
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  cursor: "pointer",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transition: "background-color 0.3s ease",
};

const hoverStyle = {
  backgroundColor: "#3f4579",
};

const AuditRequestButton = ({ onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      if (location.pathname !== "/") {
        navigate("/");
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }

      const startTime = Date.now();

      const checkElement = setInterval(() => {
        const targetElement = document.getElementById("auditForm");

        if (targetElement) {
          clearInterval(checkElement);
          const targetPosition = targetElement.getBoundingClientRect().top;
          const offsetPosition = targetPosition + window.pageYOffset - 50;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        } else if (Date.now() - startTime > 5000) {
          clearInterval(checkElement);
          navigate("/");
        }
      }, 100);

      return;
    }
  };

  return (
    <button
      style={buttonStyle}
      // onMouseEnter={(e) => Object.assign(e.target.style, hoverStyle)}
      // onMouseLeave={(e) => Object.assign(e.target.style, buttonStyle)}
      onClick={handleClick}
    >
      Request an Audit
    </button>
  );
};

export default AuditRequestButton;
