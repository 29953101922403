// Clients logo
import appliedBlockchain from "../../assets/img/clients/appliedblockchain.png";
import bankOfCanada from "../../assets/img/clients/bankofcanada.png";
import cevt from "../../assets/img/clients/cevt.png";
import dacx from "../../assets/img/clients/dacx.png";
import driveDeal from "../../assets/img/clients/drivedeal.png";
import innsBrook from "../../assets/img/clients/innsbrook.png";
import investmentPunkAcademy from "../../assets/img/clients/investmentpunkacademy.png";
import kilt from "../../assets/img/clients/kilt.png";
import lacero from "../../assets/img/clients/lacero.png";
import wob from "../../assets/img/clients/wob.png";
import woolf from "../../assets/img/clients/woolf.png";
import xain from "../../assets/img/clients/xain.png";
import animalConcerts from "../../assets/img/clients/animalConcerts.png";
import whirlprotocol from "../../assets/img/clients/whirlprotocol.png";
import quantNetwork from "../../assets/img/clients/quantNetwork.png";
import floatProtocol from "../../assets/img/clients/floatProtocol.png";
import pwn from "../../assets/img/clients/pwn.png";
// clients slider

export const clients = [
  {
    img: pwn,
    alt: "PWN logo",
  },
  {
    img: floatProtocol,
    alt: "Float Protocol logo",
  },
  {
    img: quantNetwork,
    alt: "Quant Network logo",
  },
  {
    img: whirlprotocol,
    alt: "Whirl Protocol logo",
  },
  {
    img: animalConcerts,
    alt: "Animal Concerts logo",
  },
  {
    img: bankOfCanada,
    alt: "Bank Of Canada logo",
  },
  {
    img: xain,
    alt: "Xain logo",
  },
  {
    img: cevt,
    alt: "CEVT logo",
  },
  {
    img: dacx,
    alt: "DACX logo",
  },
  {
    img: lacero,
    alt: "Lacero logo",
  },
  {
    img: appliedBlockchain,
    alt: "Applied Blockchain logo",
  },
  {
    img: wob,
    alt: "Work On Blockchain logo",
  },
  {
    img: investmentPunkAcademy,
    alt: "Investment Punk Academy logo",
  },
  {
    img: driveDeal,
    alt: "Drive Deal logo",
  },
  {
    img: innsBrook,
    alt: "INNS Brook logo",
  },
  {
    img: kilt,
    alt: "Kilt logo",
  },
  {
    img: woolf,
    alt: "Woolf logo",
  },
];
